<template>
  <div id="point-event" class="rounded border p-4 bg-white">
    <div
        v-if="pending"
        id="pending"
        class="d-flex flex-column justify-content-center align-items-center align-content-center"
    >
      <h1 class="text-danger">
        LOADING
      </h1>
      <b-spinner
          variant="danger"
          size="lg"
      />
    </div>
    <div v-else>
      <div>
        <b-breadcrumb :items="menus" />
      </div>
      <div id="info"
           v-if="Object.values(active).filter(act => act).length"
           class="border rounded shadow-sm p-4 w-100 d-flex flex-column justify-content-start align-content-start align-items-start"
      >
        <router-view
            :selectedId="info._id"
            ref="routerView"
        />
      </div>
      <hr/>
      <b-form v-on:submit.prevent="save">
        <div class="d-flex flex-column">
          <div v-if="info.regDate" class="text-right">
            <div>
              <b-badge>
                {{ new Date(info.regDate).toLocaleString() }}
              </b-badge>
            </div>
            <div>
              <b-badge>
                {{ info.regUserId }}
              </b-badge>
            </div>
          </div>
          <div class="text-left">
            <b-form-group
                label="이벤트 제목"
                description="이벤트 제목을 입력해주세요"
                label-size="sm"
            >
              <b-form-input
                  size="sm"
                  v-model="info.title"
              />
            </b-form-group>
          </div>
          <div class="text-left">
            <b-form-group
                label="이벤트 내용"
                description="이벤트 내용을 입력해주세요"
                label-size="sm"
            >
              <b-form-textarea
                  size="sm"
                  v-model="info.content"
                  style="height: 115px"
              />
            </b-form-group>
          </div>
          <div class="text-left">
            <v-date-picker v-model="info.date" is-range
                           :model-config="modelConfig"
                           :masks="masks"
                           is-dark
                           color="red">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-group
                    label="이벤트 기간"
                    description="이벤트 기간을 선택하여 주세요"
                    label-size="sm"
                >
                  <div class="form-inline form-search">
                    <div class="input-group input-date">
                      <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="" readonly
                             style="background: rgba(0,0,0,.1)"
                             :value="inputValue.start"
                             v-on="inputEvents.start">
                    </div>
                    <div class="date-to"> ~ </div>
                    <div class="input-group input-date mr-4">
                      <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="" readonly
                             style="background: rgba(0,0,0,.1)"
                             :value="inputValue.end"
                             v-on="inputEvents.end">
                    </div>
                  </div>
                </b-form-group>
              </template>
            </v-date-picker>
          </div>
          <div class="text-left">
            <b-form-group
                label="이벤트 방법"
                description="이벤트 방법을 선택하여 주세요."
                label-size="sm"
            >
              <b-form-select
                  size="sm"
                  v-model="info.eventMethod"
                  v-on:change="eventMethodChange"
                  :options="[{value: null, text: '선택'}, {value: 'payment', text: '결제'}, {value: 'login', text: '로그인'}]"
              />
            </b-form-group>
          </div>
          <div class="text-left" v-if="info.eventMethod === 'payment'">
            <b-form-group
                label="이벤트 타입"
                description="이벤트 타입을 선택하여 주세요. (없음을 선택 할 경우 조건 없이 이벤트 기간이 맞으면 추가 적립됨)"
                label-size="sm"
            >
              <b-form-select
                  size="sm"
                  v-model="info.type"
                  v-on:change="eventTypeChange"
                  :options="[{value: null, text: '없음'}, {value: 'recommender', text: '추천인'}]"
              />
            </b-form-group>
          </div>
          <div class="text-left border" v-if="recommenderShow">
            <div class="row p-3">
              <b-col v-for="item of recommenderUserList" v-bind:key="item" cols="4">
                <b-form-checkbox
                    :id="`checkbox-${item}`"
                    v-model="info.recommenders"
                    :name="`checkbox-${item}`"
                    :value="item"
                    unchecked-value="not_accepted"
                >
                  {{ item }}
                </b-form-checkbox>
              </b-col>
            </div>
          </div>

          <div class="text-left mt-3">
            <b-form-group
                label="배수"
                description="배수를 선택하여 주세요."
                label-size="sm"
            >
              <b-form-select
                  size="sm"
                  v-model="info.multiple"
                  v-on:change="multipleSelected"
                  :options="[{value: null, text: '선택'}, {value: 2, text: '2배'}, {value: 3, text: '3배'}, {value: 4, text: '4배'}, {value: 0, text: '직접입력'}]"
              />
            </b-form-group>
            <b-form inline v-if="info.multiple === 0">
              <b-form-input
                  type="number"
                  class="text-right"
                  size="sm"
                  v-model="info.point"
              />
              <label class="mr-sm-2 ml-1"> point</label>
            </b-form>
          </div>

          <div class="row mt-2">
            <div class="text-left col">
              <b-form-group
                  label="한도 여부"
                  label-size="sm"
              >
                <b-form-checkbox
                    v-model="info.pointLimitTF"
                    v-on:change="pointLimitChange"
                >
                  {{ info.pointLimitTF ? '있음' : '없음' }}
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div class="text-left col" v-if="info.pointLimitTF">
              <b-form-group
                  label="한도 point"
                  description="한도를 입력하여 주세요."
                  label-size="sm"
              >
                <b-form inline>
                  <b-form-input
                      type="number"
                      class="text-right"
                      size="sm"
                      v-model="info.pointLimit"
                  />
                  <label class="mr-sm-2 ml-1"> point</label>
                </b-form>
              </b-form-group>
            </div>

            <div class="text-left col"></div>
          </div>

          <div class="row mt-2">
            <div class="text-left col">
              <b-form-group
                  label="적립 횟수"
                  label-size="sm"
              >
                <b-form-checkbox
                    v-model="info.accumulateCount"
                >
                  {{ info.accumulateCount ? '한번' : '제한 없음' }}
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div class="text-left col">
              <b-form-group
                  label="적립 방법"
                  label-size="sm"
              >
                <b-form-checkbox
                    v-model="info.accumulateMethod"
                >
                  {{ info.accumulateMethod ? '즉시' : '추후 일괄' }}
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div class="text-left col">
              <b-form-group
                  label="사용 여부"
                  label-size="sm"
              >
                <b-form-checkbox
                    v-model="info.eventUse"
                >
                  {{ info.eventUse ? '사용' : '사용 안함' }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <hr/>

        </div>
        <div>
          <b-button
              type="submit"
              size="sm"
              squared
              block
          >
            <font-awesome-icon
                icon="save"
            />
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>



export default {
  name: "pointEventNew",
  data:() => ({
    pending: false,
    recommenderShow: false,
    recommenderUserList: [],
    info: {
      _id: null,
      title: null,
      content: null,
      eventMethod: null,
      type: null,
      recommenders: [],
      date:{
        start: null,
        end: null,
      },
      accumulateCount: false,
      accumulateMethod: true,
      multiple: null,
      point: null,
      regUserId: null,
      regDate: null,
      eventUse: false,
      pointLimitTF: false,
      pointLimit: null,
      use: true
    },
    modelConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD', // Uses 'iso' if missing
    },
    masks: {
      input: 'YYYY-MM-DD',
    }
  }),
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) {
        this.info._id = _id
        await this.load()
      }
    })()
  },
  methods: {
    async load () {
      try {
        const {data} = await this.axios({
          url: `/admin/point/event/${this.info._id}`
        })
        const {result, info, error} = data;

        if (result) {
          this.info = info

          if (this.info.type === "recommender") {
            this.recommenderShow = true
            await this.eventTypeChange();
          }
        } else {
          this.$bvToast.toast(`${error}`, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
          })
        }
      } catch (e) {}
    },

    async save() {
      if (!this.info.accumulateMethod) {
        this.$bvToast.toast(`추후 일괄 처리에 대해서 정확한 로직이 필요합니다. 추후 일괄 처리에 대해 회의가 필요하겠습니다.`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false
        })
        return
      }

      if (!confirm(`${this.info._id ? '수정' : '등록'}하시겠습니까?`)) return
      try {
        if (this.info.multiple === 0 && this.info.point.trim() === "") this.info.point = null
        const { data } = await this.axios({
          url: this.info._id ? `/admin/point/event/save/${this.info._id}` : `/admin/point/event/save`,
          method: this.info._id ? 'PATCH' : 'POST',
          data: {
            info: this.info
          }
        })
        const {result, error} = data
        if (result) {
          await this.$router.push({
            path: `/pointEvent`
          })
        } else {
          this.$bvToast.toast(`${error}`, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false
          })
        }
      } catch (e) {}
    },

    async eventTypeChange () {
      this.recommenderShow = false
      this.recommenderUserList = []

      if (this.info.type !== 'recommender') {
        this.info.recommenders = []
        return
      }

      try {
        const {data} = await this.axios({
          url: '/admin/point/event/recommenderList'
        })
        const {result, recommenderUserList} = data
        if (result) {
          this.recommenderUserList = recommenderUserList
          this.recommenderShow = true
        }
      } catch (e) {

      }
    },

    multipleSelected () {
      if (this.info.multiple !== 0) {
        this.info.point = null
      }
    },

    eventMethodChange () {
      if (this.info.eventMethod !== 'payment') {
        this.info.type = null
        this.recommenderShow = false
        this.info.recommenders = []
      }
    },

    pointLimitChange () {
      if (!this.info.pointLimitTF) {
        this.info.pointLimit = null
      }
    }
  },

  computed: {
    menus () {
      return [
        {
          text: '이벤트 참여 유저',
          to: {
            path: `/users/list/${this.selectedId}/point-history`
          },
          active: this.active.users
        }
      ]
    },

    active () {
      return {
        users: this.$route.path.endsWith('users')
      }
    },
  }
}
</script>

<style scoped>
#point-event {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
}
</style>
